@import "fonts";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Muli" !important;
}

.ecn-button {
  @apply text-primary-90 border border-gray-300 hover:bg-ecn-gray hover:border-ecn-light;
}

.ecn-button-active {
  @apply text-accent-90 bg-accent-10 hover:text-primary-90 hover:bg-ecn-gray hover:border-ecn-light;
}

.ecn-container {
  @apply gap-3 p-3 border border-gray-300 rounded-md;
}
